@charset "utf-8";

/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button {margin:0;padding:0}
fieldset,img {border:0 none;vertical-align:top;}
dl,ul,ol,li {list-style:none}
blockquote, q {quotes: none}
blockquote:before, blockquote:after,q:before, q:after {content:'';content:none}
input,select,textarea,button {vertical-align:middle}
button {border:0 none;background-color:transparent;cursor:pointer}
body {background:#fff}
body,th,td,input,select,textarea,button {font-size:12px;line-height:1.5;font-family:Malgun Gothic,"맑은 고딕",AppleGothic,Dotum,"돋움",sans-serif; color:#000;}
a {color:#333;text-decoration:none}
a:active, a:hover {text-decoration:none;}
address,caption,cite,code,dfn,em,var {font-style:normal;font-weight:normal}

html, body, div, ul, ol, dl, li, dt, dd, p,
h1, h2, h3, h4, h5, h6, table, thead, tbody, tr, th, td, caption, address,
canvas, details, figure, figcaption, footer, header, menu, nav, section, article, aside,
button, select, textarea, input, fieldset, legend, label,
a, span, strong, em, address, dfn, small, i, b, ::before, ::after {margin:0; padding:0; border:0; outline:none; letter-spacing:-0.01em;}
body, th, td, input, select, textarea, button {font-family:'Roboto', 'Noto Sans KR', 'Malgun Gothic', "맑은 고딕", 'AppleGothic', 'Dotum', '돋움', sans-serif;}
body.layerOpen {overflow:hidden;} /* 레이어팝업 오픈상태 */
[hidden], hr {display:none;}
button {cursor:pointer;}
a, button {text-decoration:none; -webkit-transition:all 0.3s ease; -moz-transition:all 0.3s ease; -o-transition:all 0.3s ease; transition:all 0.3s ease;}
button {overflow:hidden; background-color:transparent; cursor:pointer;}
button::-moz-focus-inner {border:0; padding:0;}


/*-------------------------------------------------------------------
    ## 샘플(임시)
-------------------------------------------------------------------*/
.sample-container {
    position:relative;
    width:1200px;
    margin:0 auto;
    padding:70px 0 0;
}
.sample-container .sample-container-header .sample-container-tit {
    line-height:1;
    padding:0 0 30px;
    color:#333;
    font-size:24px;
    font-weight:600;
    letter-spacing:-1px;
    text-align:center;
}
.sample-container .sample-container-header .sample-desc {
    padding-bottom:70px;
}
.sample-container .sample-container-header .sample-desc .sample-desc-wrap {
    max-width:1000px;
    margin:0 auto;
    padding:16px;
    border:1px solid #2A2D33;
    font-size:12px;
    text-align:center;
}

.nd-review-item .nd-review-item-wrap .goods-sec .thumb {
    background-image:url('../images/img/@goods.jpg');
}
.nd-review-item:first-child .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@001.jpg');
}
.nd-review-item:nth-child(2) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@002.jpg');
}
.nd-review-item:nth-child(3) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@003.jpg');
}
.nd-review-item:nth-child(4) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@004.jpg');
}
.nd-review-item:nth-child(5) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@005.jpg');
}
.nd-review-item:nth-child(6) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@006.jpg');
}
.nd-review-item:nth-child(7) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@007.jpg');
}
.nd-review-item:nth-child(8) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@008.jpg');
}
.nd-review-item:nth-child(9) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@009.jpg');
}
.nd-review-item:nth-child(10) .nd-review-item-wrap .nd-review-section .thumb {
    background-image:url('../images/img/@010.jpg');
}
.color-custom {
    color:#35c5f0!important;
}

/*-------------------------------------------------------------------
    ## 후기 리스트
-------------------------------------------------------------------*/

.nd-review-list {
    margin:0;
    padding:0;
    font-size:0;
    text-align:center;
}
.nd-review-list .nd-review-item {
    display:inline-block;
    width:20%;
    padding:0 10px;
    vertical-align:top;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    font-size:12px;
    text-align:left;
}
.nd-review-list .nd-review-item .nd-review-item-wrap {
    position: relative;
    border:1px solid #eeeeee;
}

/* review-section */

.nd-review-item .nd-review-item-wrap .nd-review-section .thumb {
    border-bottom:1px solid #eee;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}
.nd-review-item .nd-review-item-wrap .nd-review-section .thumb .thumb-transparent {
    width:100%;
}

.nd-review-item .nd-review-item-wrap .nd-review-section .info {
    padding:10px;

}
.nd-review-item .nd-review-item-wrap .nd-review-section .info .info-cont .tit {
    overflow:hidden;
    line-height:18px;
    padding-bottom:5px;
    color:#000000;
    font-size: 12px;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.nd-review-item .nd-review-item-wrap .nd-review-section .info .info-cont.profile {
    overflow:hidden;
    position:relative;
    color:#666666;
    font-size:12px;
}
.nd-review-item .nd-review-item-wrap .nd-review-section .info .info-cont.profile .author {
    margin-right:75px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.nd-review-item .nd-review-item-wrap .nd-review-section .info .info-cont.profile .date {
    position:absolute;
    top:2px;
    right:0;
    width:70px;
    padding-right:2px;
    display:block;
    text-align:right;
    color:#767676;
}

/* product-sec */

.nd-review-item .nd-review-item-wrap .goods-sec {
    position: relative;
    margin:0 10px;
    padding:13px 0;
    height:40px;
    border-top:1px solid #eeeeee;
}
.nd-review-item .nd-review-item-wrap .goods-sec .thumb {
    float:left;
    width:33px;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}
.nd-review-item .nd-review-item-wrap .goods-sec .thumb .thumb-transparent {
    width:100%;
}
.nd-review-item .nd-review-item-wrap .goods-sec .info {
    margin-left:45px;
    font-size:11px;
    line-height:15px;
    padding-top:2px;
}
.nd-review-item .nd-review-item-wrap .goods-sec .info .goods-nm {
    overflow:hidden;
    padding-bottom:4px;
    color:#000000;
    white-space:nowrap;
    text-overflow:ellipsis;
}
.nd-review-item .nd-review-item-wrap .goods-sec .info .goods-feedback {
    line-height:15px;
    color:#666666;
    font-size:0;
    font-weight:400;
}
.nd-review-item .nd-review-item-wrap .goods-sec .info .goods-feedback .feedback-data {
    display:inline-block;
    vertical-align:top;
    width:50%;
    font-size:11px;
    text-align:left;
    padding:0;
}
.nd-review-item .nd-review-item-wrap .goods-sec .info .goods-feedback .feedback-data .data {
    color:#000000;
    font-weight:bold;
}

















/* popup */

.popup-iframe {
    width:100%;
    height:100%;
    z-index:9999999;
    position:fixed;
    top:0;
    left:0;
    visibility:visible;
}
.review-popup {
    overflow:hidden;
    opacity:1;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
}
.review-popup .dim {
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
}
.review-popup .popup-cont {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
}
.review-popup .popup-cont .popup-cont-wrap {
    position:relative;
    width:513px;
    margin-top:60px;
    margin-left:auto;
    margin-right:auto;
    padding-right:288px;
    box-shadow:0 7px 50px #000000;
    background:#ffffff;
}

.review-container.img {
    display:block;
    position:relative;
    background:#eee;
    font-size:0;
    text-align:center;
}
.review-container.img img {
    height:770px;
    cursor:zoom-in;
}
.review-container.info {
    position:absolute;
    top:0;
    right:0;
    height:100%;
    width:288px;
    background-color:#ffffff;
    overflow:visible;
}
.review-container.info .goods-area {

}
.review-container.info .goods-area .goods-area-wrap {
    padding:20px 23px 16px 23px;
}



.review-area {
    overflow:auto;
    position:relative;
}
.review-area .review-area-wrap {
    width:242px;
    padding-left:23px;
}


/* 팝업 > 우측 > 싱픔 */

.goods-area .goods-area-wrap {
    padding:20px 23px 16px 23px;
}
.goods-area .goods-area-wrap .goods {
    margin-bottom:10px;
    font-size:0;
}
.goods-area .goods-area-wrap .goods .thumb {
    display: inline-block;
    width:70px;
    vertical-align: top;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    background-image:url('../images/img/@goods.jpg');
}
.goods-area .goods-area-wrap .goods .thumb img {
    width:100%;
}
.goods-area .goods-area-wrap .goods .info {
    display:inline-block;
    vertical-align: top;
    margin-left:8px;
    font-size:12px;
}
.goods-area .goods-area-wrap .goods .info .nm {
    color: #323232;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    padding-top: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.goods-area .goods-area-wrap .goods .info .author {
    font-size: 11px;
    color: #adadad;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.goods-area .goods-area-wrap .help-summary {
    border: 1px solid #e7e7e7;
    padding: 8px 9px;
    width: 222px;
    height: 30px;
    margin-top: 10px;
}
.goods-area .goods-area-wrap .help-summary .help-summary-wrap {
    line-height: 15px;
    color: #a4a4a4;
    font-size: 11px;
}
.goods-area .goods-area-wrap .help-summary .help-summary-wrap strong {
    color:#000000;
}











/* 팝업 > 우측 > 옵션 */


/* 팝업 > 우측 > 후기 */
.review-area .review-area-wrap .nd-review-description {
    position: relative;
    padding: 7px 0 30px 0;
    border-bottom: 1px dotted #eaeaea;
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    width: 242px;
}
.review-area .review-area-wrap .nd-review-description .nd-review-contents {
    padding: 0 5px;
    word-wrap: break-word;
    line-height: 1.6;
}
.review-area .review-area-wrap .nd-review-description .date {
    position: absolute;
    bottom: 3px;
    width: 100%;
    text-align: right;
    color: #cbcbcb;
}

/*-------------------------------------------------------------------
    ## 옵션
-------------------------------------------------------------------*/
.nd-review-option {
    margin-right:0;
    margin-bottom:7px;
    border:none;
    border-top:1px dashed #ddd;
    border-bottom:1px dashed #ddd;
    padding:15px 5px;
}
.nd-review-option .nd-option-box {

}
.nd-review-option .nd-option-box .nd-option-item {
    overflow:hidden;
    margin-top:4px;
    color:#333333;
}
.nd-review-option .nd-option-box .nd-option-item:first-child {
    margin-top:0;
}
.nd-review-option .nd-option-box .nd-option-item .nd-option-tit,
.nd-review-option .nd-option-box .nd-option-item .nd-option-val {
    overflow:hidden;
    float:left;
    white-space:nowrap;
    text-overflow:ellipsis;
    font-weight:bold;
}
.nd-review-option .nd-option-box .nd-option-item .nd-option-tit {
    width:40%;
}
.nd-review-option .nd-option-box .nd-option-item .nd-option-val {
    width:60%;
    text-align:right;
    color:#777;
    font-weight:normal;
}
.nd-review-option .nd-option-box .nd-option-item .nd-option-val .nd-option-selected {
    display:block;
    color:#bbb;
}
.nd-review-option .nd-option-box .nd-option-item .nd-option-val .nd-option-selected .nd-option-selected-val {
    color:#777;
}


/*-------------------------------------------------------------------
    ## 별점
-------------------------------------------------------------------*/
.nd-score {
    height: 14px;
    padding: 6px 0 15px 0;
}
.nd-score .nd-score-wrap {
    font-size:0;
}
.nd-score .nd-score-wrap .nd-score-star {
    display: inline-block;
    width: 12px;
    height: 12px;
    font-size: 0;
    background-image:url("../images/img/star.png");
}
.nd-score .nd-score-wrap .nd-score-star.empty {
    background-position:-24px 0;
}
/* 별정박스 */
.nd-score-box .nd-score {
    display:inline-block;
    vertical-align:middle;
    padding:0;
}
.nd-score-box .nd-score-txt {
    display:inline-block;
    vertical-align:middle;
    padding-left:4px;
}
/* 별정 + 도움되는리뷰 */
.nd-review-evaluation {
    padding-bottom:12px;
    color:#a4a4a4;
}
.nd-review-evaluation .nd-score-box {
    display: inline-block;
    vertical-align: middle;
}
.nd-review-evaluation .nd-help-summary {
    display: inline-block;
    vertical-align: middle;
    padding-left:5px;
}
.nd-review-evaluation .nd-help-summary .nd-help-summary-wrap {}
.nd-review-evaluation .nd-help-summary .nd-help-summary-wrap strong {
    color:#000000;
}

/*-------------------------------------------------------------------
    ## 댓글
-------------------------------------------------------------------*/
.nd-review-comment {
    padding:20px 0;
}
.nd-review-comment .nd-comment-help {
    overflow: hidden;
    width:100%;
}
.nd-review-comment .nd-comment-help .btn-fold.btn-add-reply {
    float:left;
    position: relative;
    line-height:25px;
    margin-right:10px;
    padding-right:10px;
    vertical-align:middle;
    font-weight:bold;
}
.nd-review-comment .nd-comment-help .btn-fold.btn-add-reply:after {
    content:"";
    display:block;
    position:absolute;
    top:50%;
    right:0;
    height:10px;
    margin-top:-5px;
    border-right:1px solid #999999;
}
.nd-review-comment .nd-comment-help .nd-comment-help-tit {
    float:left;
    line-height:25px;
    margin-right:0;
    vertical-align:middle;
    color:#666666;
}
.nd-review-comment .nd-comment-help .nd-comment-help-btn {
    float:right;
}
.nd-review-comment .nd-comment-help .nd-comment-help-btn .nd-btn-evaluation {
    display:inline-block;
    margin-right:3px;
    border:1px solid #dadada;
    width:50px;
    line-height:23px;
    text-align:center;
    color:#7b7b7b;
    -webkit-transition:all 0.2s ease;
    -moz-transition:all 0.2s ease;
    -o-transition:all 0.2s ease;
    transition:all 0.2s ease;
    cursor:pointer;
}
.nd-review-comment .nd-comment-help .nd-comment-help-btn .nd-btn-evaluation:hover {
    background-color:#333333;
    border-color:#333333;
    color:#ffffff;
}
.nd-review-comment .nd-comment-reply {
    position:relative;
    /*width:219px;*/
    /*max-width:800px;*/
    width:100%;
    margin-top:7px;
    margin-right:0;
    padding:10px;
    border:1px solid #e2e2e2;
    text-align:left;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
.nd-review-comment .nd-comment-reply:before {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e2e2e2;
    position: absolute;
    top: -8px;
    left: 20px;
    content: '';
}
.nd-review-comment .nd-comment-reply:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    position: absolute;
    top: -7px;
    left: 21px;
    content: '';
}
.nd-review-comment .nd-comment-reply .nd-comment-reply-wrap {
    position:relative;
    padding:4px 65px 4px 12px;
    border:1px solid #e2e2e2;
}
.nd-review-comment .nd-comment-reply .nd-comment-reply-wrap .nd-comment-reply-input {
    width: 100%;
    font-size: 13px;
    height: 24px;
    line-height: 24px;
}
.nd-review-comment .nd-comment-reply .nd-comment-reply-wrap .nd-comment-reply-btn {
    position:absolute;
    right:4px;
    top:4px;
    width: 50px;
    line-height:23px;
    border:1px solid #dadada;
    background-color:#ffffff;
    -webkit-transition:all 0.2s ease;
    -moz-transition:all 0.2s ease;
    -o-transition:all 0.2s ease;
    transition:all 0.2s ease;
    color:#7b7b7b;
    text-align:center;
    cursor:pointer;
}
.nd-review-comment .nd-comment-reply .nd-comment-reply-wrap .nd-comment-reply-btn:hover {
    background-color:#333333;
    border-color:#333333;
    color:#ffffff;
}


/*-------------------------------------------------------------------
    ## 포토리스트
-------------------------------------------------------------------*/
.nd-review-photo {
    padding:0;
}
.nd-review-photo .nd-review-photo-wrap {
    overflow:hidden;
    display:block;
    width:100%;
    margin:18px -5px 0 -5px;
    font-size:0;
}
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item {
    float:left;
    position:relative;
    overflow:hidden;
    width:128px;
    height:128px;
    margin:0 5px;
    border:1px solid #eeeeee;
    font-size:0;
}
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item .nd-photo-img {
    display:block;
    width:128px;
    height:128px;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item .nd-photo-img img {
    width:100%;
}
/* 임시 */
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item:first-child .nd-photo-img {
    background-image:url('../images/img/@001.jpg');
}
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item:nth-child(2) .nd-photo-img {
    background-image:url('../images/img/@002.jpg');
}
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item:nth-child(3) .nd-photo-img {
    background-image:url('../images/img/@003.jpg');
}
.nd-review-photo .nd-review-photo-wrap .nd-review-photo-item:nth-child(4) .nd-photo-img {
    background-image:url('../images/img/@004.jpg');
}

.nd-review-list .nd-review-item .nd-review-item-wrap .thumb-review-goods {
    background-image:url('../images/img/@goods.jpg');
}


/*-------------------------------------------------------------------
    ## review-form
-------------------------------------------------------------------*/
.nd-review-form {
    padding-bottom:44px;
}
.nd-review-form .nd-review-form-header {
    overflow:hidden;
    height:20px;
    line-height:20px;
    margin-bottom:3px;
}
.nd-review-form .nd-review-form-header .nd-review-form-tit {
    float:left;
    font-size:12px;
}
.nd-review-form .nd-review-form-header .nd-btn-area {
    float:right;
}
.nd-review-form .nd-review-form-header .nd-btn-area .nd-btn-review-board {
    display:block;
    width:auto;
    line-height:20px;
    background-color:#303030;
    color:#ffffff;
    text-align:center;
    font-size:10px;
    border:1px solid #101010;
    border-radius:3px;
    cursor:pointer;
    white-space:nowrap;
    padding:0 2px;
}
.nd-review-form .nd-review-form-body {}
.nd-review-form .nd-review-form-body .nd-message-field {
    font-size: 0;
    height: auto;
    margin-bottom: 8px;
    padding: 15px 13px;
    border: 1px solid #dadada;
    position: relative;
    transition: padding-top 0.3s ease;
}

.nd-review-form .nd-review-form-body .nd-message-field .message-research {}
.nd-review-form .nd-review-form-body .nd-message-field .message-research .message-research-wrap {
    font-size: 13px;
    border: 1px solid #dadada;
    padding: 15px 20px;
    margin-bottom: 18px;
}
.nd-review-form .nd-review-form-body .nd-message-field .message-research .message-research-wrap .research-list .research-item .select-custom {
    float:left;
    position:relative;
    width:80%;
    border:1px solid #eee;
    box-sizing: border-box;
}
.nd-review-form .nd-review-form-body .nd-message-field .message-research .message-research-wrap .research-list .research-item .research-item-wrap {
    position:relative;
    padding:4px 0;
}
.nd-review-form .nd-review-form-body .nd-message-field .message-research .message-research-wrap .research-list .research-item .research-item-wrap:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
.nd-review-form .nd-review-form-body .nd-message-field .message-research .message-research-wrap .research-list .research-item .research-item-wrap .tit {
    float:left;
    width:20%;
}





.nd-review-form .nd-review-form-body .nd-message-field .nd-message-textarea {
    min-height:100px;
    height:auto;
    font-size:14px;
    line-height:20px;
    margin:0px;
    border:0;
    width:100%;
    outline:transparent;
    resize:none;
    overflow:hidden;
}
.nd-review-form .nd-review-form-body .nd-message-field .nd-limit-counter {
    position:absolute;
    bottom:5px;
    right:7px;
    font-size:12px;
    color:#626262;
}
.nd-review-form .nd-review-form-body .nd-message-submit {
    position: relative;
    height:35px;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-attach-img {
    position:absolute;
    left:0;
    width:204px;
    height:33px;
    border:1px solid #dadada;
    margin-right:5px;
    text-align:center;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-attach-img .nd-ico-attach {
    display:inline-block;
    position:absolute;
    top:4px;
    left:15px;
    vertical-align: middle;
    background-repeat: no-repeat;
    width: 38px;
    height: 27px;
    background-position: -22px -514px;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-attach-img .nd-btn-attach-txt {
    position: absolute;
    color: #8f8f8f;
    top:9px;
    left: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score {
    margin:0 300px 0 213px;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap {
    margin: 0;
    position: relative;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle;
    /**/
    border: 1px solid #dadada;
    font-size: 14px;
    display: block;
    padding: 4px 10px;
    height: 25px;
    /**/
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap .nd-select-score-container {
    display: block;
    height: 26px;
    padding: 0;
    overflow: hidden;
    position: relative;
    border: none;
    white-space: nowrap;
    line-height: 26px;
    color: #444;
    text-decoration: none;
    border-radius: 0;
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    box-shadow: none;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap .nd-select-score-container .nd-chosen {
    margin-right: 26px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap .nd-select-score-container .nd-ico-arrow-down {
    position:absolute;
    top:50%;
    right:0;
    margin-top:-12px;
}

.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap .nd-select-drop {
    display:none;
    z-index:10;
    position:absolute;
    top:33px;
    left:-1px;
    width:calc(100% + 2px);
    border:1px solid rgba(0,0,0,0.1);
    background:#ffffff;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap .nd-select-drop .nd-select-drop-wrap {
    max-height: 200px;
    padding:2px 10px;
    margin:4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-select-score .nd-select-score-wrap .nd-select-drop .nd-select-drop-wrap .nd-select-drop-item {
    padding:1px 0;
    cursor:pointer;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-btn-review-submit {
    position:absolute;
    right:0;
    top:0;
    width:228px;
    height:35px;
    background:#3f3f3f;
    color:#ffffff;
    font-size:14px;
    font-weight:bold;
    cursor:pointer;
    transition:background-color 0.2s;
}
.nd-review-form .nd-review-form-body .nd-message-submit .nd-btn-review-submit .nd-ico {
    display:inline-block;
    vertical-align:middle;
    width:18px;
}

/*-------------------------------------------------------------------
    ## review-summary
-------------------------------------------------------------------*/
.nd-review-summary {
    padding: 20px 0 20px 29px;
    border: 1px solid #dadada;
}
.nd-review-summary .nd-review-summary-wrap {
    position: relative;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content {
    min-width:90px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-review-summary-content-body {}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-average {
    float:left;
    width:96px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-average .nd-average-num {
    line-height:77px;
    text-align:center;
    background:#252525;
    color:#ffffff;
    font-size:50px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-average .nd-average-count {
    padding:7px;
    text-align:center;
    color:#767676;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter {
    padding-left:120px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item {
    display: inline-block;
    margin-bottom: 9px;
    height: 14px;
    width: 100%;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link {
    display: block;
    color: #9d9d9d;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-tit {
    float:left;
    width:50px;
}

.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-count {
    float:right;
    width:64px;
    margin-left:6px;
    *min-height:0;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-count .nd-score-num {
    float: left;
    text-align: right;
    color: #9d9d9d;
    width: 42px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-count .nd-ico-check {
    float: left;
    /*visibility: hidden;*/
    margin-left:5px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-count .nd-ico-check .nd-ico {
    width:12px;
    height:12px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-bar {
    margin-left: 54px;
    margin-right: 70px;
    background: #f4f4f4;
    position: relative;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-score-filter .nd-score-filter-item .nd-score-link .nd-score-bar .nd-score-percent {
    width:85%;
    background: #ff4c23;
    height: 14px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-review-summary-content-footer {
    margin-top:16px;
    font-size:14px;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-review-summary-content-footer .nd-like-percentage {
    color:#6eb67e;
}
.nd-review-summary .nd-review-summary-wrap .nd-review-summary-content .nd-review-summary-content-footer .nd-like-useruser {

}

/*-------------------------------------------------------------------
    ## 필터 review-filter
-------------------------------------------------------------------*/
.nd-review-filter {
    font-size:16px;
}
.nd-review-filter .nd-filter-sequence {
    position: relative;
}
.nd-review-filter .nd-filter-sequence .nd-sequence-item {
    position:relative;
    display:inline-block;
    padding:0 13px;
    color:#949494;
    font-size:13px;
    letter-spacing:-1px;
}
.nd-review-filter .nd-filter-sequence .nd-sequence-item:after {
    content:"";
    display:block;
    position:absolute;
    top:50%;
    left:0;
    height:10px;
    margin-top:-5px;
    border-right:1px solid #999999;
}
.nd-review-filter .nd-filter-sequence .nd-sequence-item.selected {
    padding-left:0;
    color:#333333;
    font-size:16px;
}
.nd-review-filter .nd-filter-sequence .nd-sequence-item.selected:after {
    display:none;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip {
    position: relative;
    float:right;
    line-height:30px;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-tooltip-tit {
    font-size: 10px;
    color: #8e8e8e;
    vertical-align: top;
    cursor: pointer;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-tooltip-tit .nd-ico {
    width:10px;
    height:10px;
    margin-left:4px;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-description {
    opacity:0;
    visibility:hidden;
    z-index:1;
    position:absolute;
    right:0;
    top:30px;
    width:194px;
    border-radius:10px;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #dadada;
    background-color: #ffffff;
    padding: 27px 13px;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    transition:all 0.3s ease;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip:hover .nd-description {
    opacity:1;
    visibility:visible;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-description .nd-description-wrap {}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-description .nd-description-wrap .nd-description-item {}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-description .nd-description-wrap .nd-description-item .nd-description-tit {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #444444;
}
.nd-review-filter .nd-filter-sequence .nd-tooltip .nd-description .nd-description-wrap .nd-description-item .nd-description-subs {
    font-size: 10px;
    line-height: 1.4;
    color: #4a4a4a;
    margin-top: 4px;
}




/*-------------------------------------------------------------------
    ## 구분선
-------------------------------------------------------------------*/
.nd-bar-separator {
    height: 1px;
    background: #dadada;
    margin: 10px 0;
}

/*-------------------------------------------------------------------
    ## 필터 셀렉트 박스 그룹
-------------------------------------------------------------------*/
.nd-filter-sort {
    position: relative;
    padding-top:10px;
    padding-bottom:32px;
}
.nd-filter-sort:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container {
    float:right;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item {
    float:left;
    font-size:13px;
    color:#b8b8b8;
    min-width:120px;
    max-width:220px;
    height:28px;
    border:1px solid #ededed;
    margin: 8px 15px 0 0;
    position: relative;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-tit {
    line-height: 28px;
    display: block;
    font-size:13px;
    color:#b8b8b8;
    cursor:pointer;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-tit .nd-sort-tit-txt {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 36px;
    color: #444;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-tit .nd-btn-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 28px;
    border-left: 1px solid #ededed;
    text-align: center;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-tit .nd-btn-dropdown .nd-ico {
    width:20px;
    height:20px;
    margin-top:4px;

}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-drop {
    display:none;
    position: absolute;
    width:100%;
    min-width: 80px;
    top: 28px;
    right: -1px;
    border: 1px solid #e1e1e1;
    text-align: center;
    background: white;
    z-index: 1;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-drop .nd-sort-drop-wrap {
    min-width: 90px;
    padding: 10px 15px;
}
.nd-filter-sort .nd-filter-sort-wrap .nd-sort-container .nd-sort-item .nd-sort-drop .nd-sort-drop-wrap .nd-sort-drop-item {
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
}

/*-------------------------------------------------------------------
    ## 페이지 타이틀
-------------------------------------------------------------------*/
.review-page-tit {
    position: relative;
    line-height:16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom:1px solid #dadada;
    color:#333;
    font-size:12px;
}
.review-page-tit:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
.review-page-tit .review-page-tit-wrap .tit {}
.review-page-tit .review-page-tit-wrap .sub-tit {
    position:relative;
    display:inline-block;
    margin-left:8px;
    padding-left:8px;
}
.review-page-tit .review-page-tit-wrap .sub-tit:before {
    content:"";
    display:block;
    position:absolute;
    top:50%;
    left:0;
    height:10px;
    margin-top:-5px;
    border-right:1px solid #999999;
}

/*-------------------------------------------------------------------
    ## 리뷰 메뉴
-------------------------------------------------------------------*/
.review-menu {
    position: relative;
    border: 1px solid #e1e1e1;
    margin-top: 37px;
    margin-bottom: 33px;
    box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.03);
    filter: progid:DXImageTransform.Microsoft.Shadow(color='#f7f7f7', Direction=90, Strength=3);
    height: 51px;
    box-sizing: content-box;
}
.review-menu .review-menu-wrap {
    position: relative;
    height: 51px;
    line-height: 51px;
}
.review-menu .review-menu-wrap:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
}
.review-menu .review-menu-wrap .menu-item {
    display:inline-block;
    height:51px;
    border-left: 1px solid #e1e1e1;
    padding: 0 15px;
    float: left;
}
/* 리뷰메뉴 > 좌측 카테고리별보기 */
.review-menu .review-menu-wrap.review-menu-category {
    transition:padding-right 0.3s;
    padding-right:352px;
}
.review-menu .review-menu-wrap.review-menu-category .menu-category {
    position: relative;
    float: none;
    display: block;
    padding: 8px 15px;
    border-left: 0;
}
.review-menu .review-menu-wrap.review-menu-category .menu-category .menu-category-tit {
    margin-right: 5px;
    position: absolute;
    top: 0;
}
.review-menu .review-menu-wrap.review-menu-category .menu-category .menu-category-wrap.select-custom {
    position: relative;
    border: 1px solid #dadada;
    font-size: 14px;
    display: block;
    padding: 4px 10px;
    height: 25px;
    line-height:25px;
    margin-left: 100px;
}
.review-menu .review-menu-wrap.review-menu-category .menu-category .menu-category-wrap.select-custom .select-custom-tit .tit-txt {
    padding-left:0;
}

/* 리뷰메뉴 > 우측 정렬순,검색 */
.review-menu .review-menu-wrap.review-menu-filter {
    z-index:1;
    float:right;
    position:absolute;
    top:0;
    right:0;
}

.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-sort {
    padding:0;
    width:121px;
    font-size:0;
}
.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-sort .menu-sort-wrap {
    margin: 0;
    position: relative;
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
    font-size: 14px;
    height: 51px;
    width: 100%;
}
.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-search {
    position:relative;
    padding:0;
}
.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-search .btn-menu-search {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:40px;
}
.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-search .btn-menu-search .nd-ico {
    position: absolute;
    top:50%;
    left:17px;
    width:15px;
    height:15px;
    margin-top: -8px;
    color: #7e7e7e;
}

.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-search .menu-search-input {
    display: block;
    font-size: 12px;
    vertical-align: middle;
    padding: 18px 15px 18px 39px;
    height: 14px;
    width: 61px;
    transition: width 0.3s;
}

.review.basic.gallery .review-menu.focused .review-menu-wrap.review-menu-category {
    padding-right:316px;
}
.review-menu.focused .review-menu-wrap.review-menu-filter .menu-item.menu-search .menu-search-input {
    width:141px;
}

/*-------------------------------------------------------------------
    ## 셀렉박스 커스텀
-------------------------------------------------------------------*/
.select-custom .select-custom-tit {
    display: block;
    position: relative;
    font-size:13px;
    color:#b8b8b8;
    cursor:pointer;
}
.select-custom .select-custom-tit .tit-txt {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 36px;
    color: #444;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.select-custom .select-custom-tit .btn-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    /*height: 28px;*/
    /*border-left: 1px solid #ededed;*/
    text-align: center;
}
.select-custom .select-custom-tit .btn-dropdown .nd-ico {
    display:inline-block;
    vertical-align:middle;
    width:20px;
    height:20px;
}
.select-custom .select-custom-drop {
    display:none;
    position: absolute;
    width:100%;
    min-width: 80px;
    top:100%;
    right: -1px;
    border: 1px solid #e1e1e1;
    text-align: center;
    background: white;
    z-index: 1;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
}
.select-custom .select-custom-drop .select-custom-drop-wrap {
    min-width: 90px;
    padding:4px;
}
.select-custom .select-custom-drop .select-custom-drop-wrap .select-custom-drop-item {
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    cursor:pointer;
}
.select-custom .select-custom-drop .select-custom-drop-wrap .select-custom-drop-item:hover {
    background-color:#333;
}
.select-custom .select-custom-drop .select-custom-drop-wrap .select-custom-drop-item:hover .option-txt {
    color:#ffffff;
}
.select-custom .select-custom-drop .select-custom-drop-wrap .select-custom-drop-item .option-txt {
    display:block;
    padding:3px 7px 4px;
}

/*-------------------------------------------------------------------
    ## 공지사항
-------------------------------------------------------------------*/
.review-notice {
    padding-top:3px;
}
.review-notice .review-notice-tit {
    overflow: hidden;
    padding:0 14px 9px 10px;
}
.review-notice .review-notice-tit .notice-tit-item {
    float:left;
    color:#606060;
    font-size:11px;
}
.review-notice .review-notice-tit .notice-tit-item .notice-cnt {
    display:inline-block;
    font-size: 19px;
    padding-right: 3px;
}
.review-notice .review-notice-tit .notice-tit-item .tit-txt {
    display:inline-block;
    vertical-align: top;
    line-height: 17px;
}
.review-notice .review-notice-wrap {
    border: 1px solid #e1e1e1;
    padding: 16px 28px;
    margin-bottom: 5px;
}
.review-notice .review-notice-wrap .review-notice-item .notice-tit {
    overflow: hidden;
    display: block;
    padding: 11px 0;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    transition:all 0.3s ease;
    cursor: pointer;
}
.review-notice .review-notice-wrap .review-notice-item .notice-tit .tit {
    float: left;
    font-size: 13px;
    line-height: 13px;
    color: #2d2d2d;
}
.review-notice .review-notice-wrap .review-notice-item .notice-tit .date {
    float: right;
    font-size: 13px;
    line-height: 13px;
    color: #767676;
}
.review-notice .review-notice-wrap .review-notice-item .notice-cont {
    display:none;
    padding:7px 0;
    line-height:1.6;
    word-wrap:break-word;
}
.review-notice .review-notice-wrap .review-notice-item .notice-cont .notice-cont-wrap {
    border: 1px solid #e2e2e2;
    padding: 20px 15px;
}
/* hover */
.review-notice .review-notice-wrap .review-notice-item:hover .notice-tit {
    background-color:#f8f8f8;
}
/* active */
.review-notice .review-notice-wrap .review-notice-item.on .notice-cont {
    display:block;
}





/*-------------------------------------------------------------------
    ## 툴팁
-------------------------------------------------------------------*/
.nd-tooltip {
    position: relative;
    float:right;
    line-height:30px;
}
.nd-tooltip .tit {
    font-size: 10px;
    color: #8e8e8e;
    vertical-align: top;
    cursor: pointer;
}
.nd-tooltip .tit .nd-ico {
    width:10px;
    height:10px;
    margin-left:4px;
}
.nd-tooltip .nd-description {
    opacity:0;
    visibility:hidden;
    z-index:1;
    position:absolute;
    right:0;
    top:30px;
    width:194px;
    border-radius:10px;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #dadada;
    background-color: #ffffff;
    padding: 27px 13px;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    transition:all 0.3s ease;
}
.nd-tooltip:hover .nd-description {
    opacity:1;
    visibility:visible;
}
.nd-tooltip .nd-description .nd-description-wrap {}
.nd-tooltip .nd-description .nd-description-wrap .nd-description-item {}
.nd-tooltip .nd-description .nd-description-wrap .nd-description-item .nd-description-tit {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #444444;
}
.nd-tooltip .nd-description .nd-description-wrap .nd-description-item .nd-description-subs {
    font-size: 10px;
    line-height: 1.4;
    color: #4a4a4a;
    margin-top: 4px;
}



/*-------------------------------------------------------------------
    ## 상품상세페이지 > 기본 > 리스트위젯
-------------------------------------------------------------------*/
.nd-review-wrap {
    padding:44px 0 0;
}
.nd-goodsview-basic-list .nd-review-list .nd-review-item {
    display:block;
    width:100%;
    padding:30px 0 0;
    border-top:1px solid #eeeeee;
}
.nd-goodsview-basic-list .nd-review-list .nd-review-item:first-child {
    border-top:none;
}
.nd-goodsview-basic-list .nd-review-list .nd-review-item .nd-review-item-wrap {
    overflow:hidden;
    border:none;
}
.nd-goodsview-basic-list .nd-review-list .nd-review-item .nd-review-item-wrap .left {
    margin-right:250px;
    padding-right:10px;
    min-height:150px;
}
.nd-goodsview-basic-list .nd-review-list .nd-review-item .nd-review-item-wrap .right {
    float:right;
    min-width:205px;
    max-width:250px;
    margin-bottom:30px;
}

.nd-goodsview-basic-list .nd-review-option {
    margin-bottom:18px;
    margin-right:30px;
    padding:15px;
    border:1px solid #ddd;
    font-size:12px;
}
.nd-goodsview-basic-list .nd-review-option .nd-option-box .nd-option-item .nd-option-tit {
    width:15%;
}
.nd-goodsview-basic-list .nd-review-option .nd-option-box .nd-option-item .nd-option-val {
    width:85%;
    text-align:left;
}
.nd-goodsview-basic-list .nd-review-comment {
    padding-top:13px;
}
.nd-goodsview-basic-list .nd-review-comment .nd-comment-help .nd-comment-help-btn {
    float:left;
    padding-left:12px;
}
.nd-goodsview-basic-list .nd-review-comment .nd-comment-help .nd-comment-help-cnt {
    position: relative;
    display: inline-block;
    background: #5e5e5e;
    border: 1px solid #ffffff;
    min-width: 20px;
    line-height: 23px;
    text-align: center;
    margin: 0 8px 0 7px;
    padding: 0 5px;
}
.nd-goodsview-basic-list .nd-review-comment .nd-comment-help .nd-comment-help-cnt:before {
    content:"";
    position: absolute;
    top: 2px;
    left: -5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #5e5e5e;
    font-size: 0;
    line-height: 0;
}
.nd-goodsview-basic-list .nd-review-comment .nd-comment-help .nd-comment-help-cnt .comment-help-cnt {
    color:#ffffff;
}
.nd-goodsview-basic-list .nd-review-info {}
.nd-goodsview-basic-list .nd-review-info .nd-review-info-wrap {}
.nd-goodsview-basic-list .nd-review-info .nd-review-info-wrap .nd-review-info-item {
    line-height:18px;
    padding:7px 5px;
    border-top:1px solid #eeeeee;
    color:#767676;
}
.nd-goodsview-basic-list .nd-review-info .nd-review-info-wrap .nd-review-info-item:first-child {
    padding-top:0;
    border:none;
}
.nd-goodsview-basic-list .nd-review-info .nd-review-info-wrap .nd-review-info-item .nd-review-info-val {
    color:#333333;
    font-weight:bold;
}

/* 상품후기 더보기 */
.nd-goodsview-basic-list .nd-review-section-bottom .nd-review-option,
.nd-goodsview-basic-list .nd-review-section-bottom .nd-review-comment .nd-comment-reply {
    display:none;
}

.nd-goodsview-basic-list .nd-review-section-bottom.on .nd-review-option,
.nd-goodsview-basic-list .nd-review-section-bottom.on .nd-review-comment .nd-comment-reply {
    display:block;
}
.nd-goodsview-basic-list .pagination-sec {
    border-top:1px solid #eeeeee;
}


/*-------------------------------------------------------------------
    ## 상품상세페이지 - 옵션 - 갤러리위젯
-------------------------------------------------------------------*/
.goodsview-option-gallery {
    width:948px;
    margin:0 auto;
}
.goodsview-option-gallery .review-list .review-item {
    width:25%;
    padding-bottom:26px;
}
.goodsview-option-gallery .review-item .review-item-wrap .goods-sec {
    display:none;
}
.goodsview-option-gallery .pagination-sec {
    border-top:1px solid #dadada;
}

/*-------------------------------------------------------------------
    ## 메인 - 옵션 - 갤러리 - 둥근썸네일
-------------------------------------------------------------------*/
.main-option.gallery.circle-thumb {
    width:1177px;
    margin:0 auto;
}
.main-option.gallery.circle-thumb .review-list .review-item {
    position:relative;
    width:20%;
    padding-bottom:50px;
}
/* 후기제목,작성자,날짜 */
.main-option.gallery.circle-thumb .review-item .review-item-wrap .review-section .info {
    position: absolute;
    top:242px;
    right: 1px;
    width:108px;
    border-bottom: 0;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .review-section .info .info-cont .tit {
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    white-space: normal;
    height:2.5em;
    line-height:140%;
    padding:0;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .review-section .info .info-cont.profile {
    overflow:visible;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .review-section .info .info-cont.profile .author {
    position:absolute;
    top:-94px;
    right:70px;
    margin-right:0;
    color:#fff;
    font-size:12px;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .review-section .info .info-cont.profile .date {
    position: absolute;
    top:-94px;
    right: -3px;
    width: 70px;
    color: #fff;
    font-size: 12px;
}
/* 상품썸네일,상품명,리뷰수,평점 */
.main-option.gallery.circle-thumb .review-item .review-item-wrap .goods-sec {
    position:static;
    height:85px !important;
    border-top:none;
    background:#fff;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .goods-sec .thumb {
    position:absolute;
    bottom:20px;
    width:72px;
    height:72px;
    border-radius:100%;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .goods-sec .info {
    margin-left:85px;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .goods-sec .info .goods-nm {
    position: absolute;
    top:234px;
    width: 110px;
    font-size: 12px;
    font-weight: bold;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .goods-sec .info .goods-feedback {
    position:absolute;
    bottom:14px;
    width:calc(100% - 105px);
    color:#aaa;
    font-size:0;
}
.main-option.gallery.circle-thumb .review-item .review-item-wrap .goods-sec .info .goods-feedback .feedback-data .data {
    color:#aaa;
    font-weight: normal;
}
.main-option.gallery.circle-thumb .pagination-sec {
    border-top:1px solid #dadada;
}

/*-------------------------------------------------------------------
    ## 상품후기페이지 > 기본 > 리스트위젯
-------------------------------------------------------------------*/
/* 상단 > 카테고리별보기 */
.review.basic.list .review-menu.focused .review-menu-wrap.review-menu-category {
    padding-right:432px;
}
/* 상단 > 상품별리뷰보기버튼 */
.review-menu .review-menu-wrap .menu-item a {
    display:block;
    color:#aaaaaa;
}

/* 후기리스트 */
.review-list .review-item .review-item-wrap .thumb-review-goods {
    position: absolute;
    width: 90px;
    top:0;
    left:0;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
}
.review-list .review-item .review-item-wrap .thumb-review-goods .img-thumb {
    width:100%;
}
.review.basic.list.goodsview-basic-list .review-list .review-item .review-item-wrap .left {
    padding-left:108px;
}
.goods-nm {
    color:#333333;
    font-weight:bold;
}
.review.basic.list .review-comment .comment-help .btn-fold.btn-add-reply {
    margin-right:0;
    padding-right:0;
}
.review.basic.list .review-comment .comment-help .btn-fold.btn-add-reply:after {
    display:none;
}
.review.basic.list .review-comment .comment-help .review-comment-wrap {
    display:none;
    position:relative;
    margin-left:10px;
    padding-left:10px;
    vertical-align:top;
}
.review.basic.list .review-comment .comment-help .review-comment-wrap:before {
    content:"";
    display:block;
    position:absolute;
    top:50%;
    left:0;
    height:10px;
    margin-top:-5px;
    border-right:1px solid #999999;
}
.review.basic.list .review-section-bottom.on .review-comment .comment-help .review-comment-wrap {
    display:inline-block;
}
.review.basic.list.goodsview-basic-list .review-list .review-item .review-item-wrap .right {
    position: absolute;
    top:0;
    right:0;
    max-width:unset;
}
.review.basic.list.goodsview-basic-list .review-info .review-info-wrap {
    text-align: right;
}
.review.basic.list.goodsview-basic-list .review-info .review-info-wrap .review-info-item {
    display:inline-block;
    padding:0;
    border:none;
    text-align: right;
}
.review.basic.list.goodsview-basic-list .review-info .review-info-wrap .review-info-item.date{
    width:130px;

}
.review.basic.list.goodsview-basic-list .review-info .review-info-wrap .review-info-item.date .review-info-val {
    color:#aaa;
    font-weight:normal;
}
.review.basic.list.goodsview-basic-list .review-photo {
    float:right;
    font-size:0;
    margin-top:15px;
}
.review.basic.list.goodsview-basic-list .review-photo .review-photo-wrap {
    margin:0;
}
.review.basic.list.goodsview-basic-list .review-photo .review-photo-wrap .review-photo-item {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    margin:0 0 0 4px;
}
.review.basic.list.goodsview-basic-list .review-photo .review-photo-wrap .review-photo-item .photo-img {
    width:40px;
    height:40px;
}
/* 상품별리뷰페이지 > 상단 */
.review.basic.list.goods-type {}
.review.basic.list.goods-type .review-menu .review-menu-wrap .menu-item.this {
    background:#333333;
    color:#ffffff;
}
.review.basic.list.goods-type .review-menu .review-menu-wrap .menu-item.this a {
    color:#ffffff;
}
/* 상품별리뷰페이지 > 후기리스트 */
.review.basic.list.goods-type .review-list .review-item {
    position:relative;
    min-height:121px;
    margin-bottom:30px;
    padding:14px 10px;
    border:1px solid #e4e4e4;
}
.review.basic.list.goods-type .review-list .review-item .thumb-review-goods {
    position:absolute;
    top:14px;
    left:10px;
    width:90px;
    height:90px;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    background-image:url('../images/img/@goods.jpg');/* 임시 */
}
.review.basic.list.goods-type .review-list .review-item .thumb-review-goods img {
    width:100%;
}
.review.basic.list.goods-type .review-list .review-item .review-item-wrap {
    padding-left:116px;
}
.review.basic.list.goods-type .review-container.top {
    position: relative;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #323232;
    margin-bottom: 15px;
    border-bottom: 1px solid #e4e4e4;
}
.review.basic.list.goods-type .review-container.top .goods-nm {
    display: block;
    margin-right: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.review.basic.list.goods-type .review-container.top .review-all {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #a8a8a8;
    font-weight: normal;
}
.review.basic.list.goods-type .review-container.body {}
.review.basic.list.goods-type .review-container.body .review-product-item {}
/* 상품별리뷰페이지 > 후기리스트 > 타이틀 */
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary {
    overflow:hidden;
    line-height: 26px;
    color: #323232;
    font-size: 12px;
    cursor:pointer;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .tit {
    float:left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .tit .tit-txt {
    vertical-align: middle;
    color: #323232;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .info {
    float:right;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .info .nd-score {
    display:inline-block;
    vertical-align: middle;
    padding:0;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .info .reply-cnt {
    display:inline-block;
    vertical-align: middle;
    width: 40px;
    min-width: 40px;
    padding-left: 20px;
    text-align: left;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .info .reply-cnt .nd-ico {
    display:inline-block;
    vertical-align: middle;
    width:12px;
    height:12px;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .info .reply-cnt .nd-ico .reply-cnt-num {
    display:inline-block;
    vertical-align: middle;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-summary .info .author {
    display:inline-block;
    vertical-align:middle;
    text-align:center;
    color:#494949;
    font-weight:bold;
    width:90px;
    min-width:90px;
    min-height:1px;
    padding-right:6px;
    float:right;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
/* 상품별리뷰페이지 > 후기리스트 > 후기내용 */
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-detail {
    display:none;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-detail .review-product-detail-wrap {
    margin-right:0;
    margin-bottom:10px;
    max-width:800px;
}
.review.basic.list.goods-type .review-container.body .review-product-item .review-product-detail .review-product-detail-wrap .review-product-detail-container {
    margin-right:100px;
    padding:44px 0;
}
.review.basic.list.goods-type .review-option {
    margin-right:0;
}
.goodsview-basic-list.review.basic.list.goods-type .review-photo {
    float:none;
}
.goodsview-basic-list.review.basic.list.goods-type .review-photo .review-photo-wrap .review-photo-item {
    width:128px;
    height:128px;
    margin:0 5px;
}
.goodsview-basic-list.review.basic.list.goods-type .review-photo .review-photo-wrap .review-photo-item:first-child {
    margin-left:0;
}
.review.basic.list.goodsview-basic-list.goods-type .review-photo .review-photo-wrap .review-photo-item .photo-img {
    width:128px;
    height:128px;
}
.review.basic.list.goodsview-basic-list.goods-type .review-comment .comment-help .review-comment-wrap {
    display:inline-block;
}





/*-------------------------------------------------------------------
    ## 상품후기페이지 > 기본 > 갤러리
-------------------------------------------------------------------*/
.review.basic.gallery .review-menu .review-menu-wrap.review-menu-category {
    padding-right:238px;
}
.review.basic.gallery .nd-tooltip {
    z-index:10;
    float:none;
    position:absolute;
    top:-31px;
    right:-1px;
}
.review.basic.gallery .review-list .review-item {
    margin-bottom:50px;
}
.review.basic.gallery .pagination-sec {
    border-top:1px solid #dadada;
}





/*-------------------------------------------------------------------
    ## 상품후기페이지 > 기본 > 상품목록위젯
-------------------------------------------------------------------*/

.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-sort {
    width:auto;
}
.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-search .menu-search-wrap {
    display:inline-block;
    vertical-align: top;
}
.review-menu .review-menu-wrap.review-menu-filter .menu-item.menu-search .sort-price {
    position: relative;
    display:inline-block;
    width:200px;
    vertical-align: top;
    border-left:1px solid #eeeeee;
}
.page-review.type-basic.widget-goods .goods-list .goods-item .goods-image-box .goods-image {
    background-image:url('../images/img/@goods.jpg');
}


/*-------------------------------------------------------------------
    ## 상품상세페이지 > 기본 > 게시판위젯
-------------------------------------------------------------------*/
.goodsview-basic-list.board .nd-review-summary .nd-review-summary-wrap .nd-review-summary-content {
    padding: 9px 20px 9px 0;
    border-right: 1px solid #ededed;
    margin-right: 263px;
}
.review-top-photo {
    position:absolute;
    top:0;
    right:0;
}
.review-top-photo .review-top-photo-wrap {
    width:245px;
}
.review-top-photo .review-top-photo-wrap .photo-group {
    text-align: center;
    font-size: 0;
    margin-top: 8px;
}
.review-top-photo .review-top-photo-wrap .photo-group .photo-list {
    overflow:hidden;
    margin-top:8px;
}
.review-top-photo .review-top-photo-wrap .photo-group .photo-list:first-child {
    margin-top:0;
}
.review-top-photo .review-top-photo-wrap .photo-group .photo-list .photo-item {
    height: 54px;
    width: 54px;
    float: left;
    overflow: hidden;
    margin-left: 4px;
    background: #ededed;
}
.review-top-photo .review-top-photo-wrap .photo-group .photo-list .photo-item:first-child {
    margin-left:0;
}
.review-top-photo .review-top-photo-wrap .photo-group .photo-list .photo-box {
    display:block;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    background-image:url('../images/img/@003.jpg');
}
.review-top-photo .review-top-photo-wrap .photo-group .photo-list .photo-box img {
    width:100%;
}
.review-top-photo .review-top-photo-wrap .photo-view {
    margin-top:18px;
    text-align:center;
}





.goodsview-basic-list.board .review-board-table {
    width:100%;
}
.goodsview-basic-list.board .review-section-bottom {
    display:none;
    width:800px;
    padding:30px 34px 42px 34px;
}
.goodsview-basic-list.board .review-section-bottom .nd-review-container .nd-review-option {
    display:block;
    line-height:1.2;
    margin-right:0;
}
.goodsview-basic-list .review-section-bottom .review-comment .comment-reply {
    display:block;
}







